
.cms-overlay {
  /* display: flex; */
  position: absolute ;
  overflow: scroll;
  top: 0px;
  width: 50%;
  height: 100%;
  z-index: 5;
  font-family: Arial, Helvetica, sans-serif;
  padding : 10px;
  font-size: 10px ;
  background-color: rgba(205, 205, 205, 0.9) !important;
  flex-direction: column;
}

.cms-style button {
  margin: 5px;
  font-size: 8px;
}
.cms-style span {
  margin: 5px;
  font-size: 8px;
}
.cms-style input {
  width: 100px;
  margin: 5px;
  font-size: 10px;
}

#ui-slider {
  margin: 5px;
  font-size: 10px;
}

.cms-style {
  /* position: absolute;
    top: 0px; */
  /* display: flex; */
  /* flex-direction: column; */
  align-self: center;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding-top: 10px;
  /* bottom: 10px; */
  /* -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  z-index: 1; */
}
/* #babylonComp {
    position: absolute  !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    width: 50px !important;
  
    /* z-index: 1; */
/* height: 400px; */
/* width: 400px; */
/* height: 100vh; */
/* width: 100vw; */
/* touch-action: none; */
/* padding: 50px; */
/* scroll-behavior: unset; */
/* overflow: hidden !important; */
/* } */
